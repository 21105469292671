import React, { useContext } from "react";
import classNames from "classnames";

import { HeroFeatureList } from "components/views/HeroFeatureList";
import { SiteContext } from "components/layout/SiteLayout";

const HomePage = () => {
  const siteData = useContext(SiteContext);

  return (
    <HeroFeatureList
      title={"Welcome"}
      subtitle={siteData.metadata.title}
      // instructions={"Select a feature from the list of presented options"}
      features={siteData.mainMenu}
      className={classNames("flex-fill")}
    />
  );
};

export default HomePage;
