import React, { useContext } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Link, useStaticQuery } from "gatsby";

import { SiteContext } from "components/layout/SiteLayout";
import { DocsHeading } from "components/docs/DocsHeading/DocsHeading";
import { ViewInstructions } from "components/views/ViewInstructions/ViewInstructions";
import { Icon } from "components/Icon";
import { query as SiteLayoutQuery } from "components/layout/SiteLayout/SiteLayout";

const HeroFeatureOption = ({
  iconName = "headLightbulbOutline",
  to = "/",
  title = "Feature Title",
  description = "No description provided",
}) => {
  return (
    <div className={classNames("col", "d-flex", "align-items-start")}>
      <Icon
        name={iconName}
        title={title}
        size={1.25}
        className={classNames(
          "lh-1",
          "bi",
          "opacity-75",
          "flex-shrink-0",
          "me-2"
        )}
      />
      <div>
        <Link to={to} className={classNames("h5", "fw-bold", "mb-0")}>
          {title}
        </Link>
        <p>{description}</p>
      </div>
    </div>
  );
};

const HeroFeatureList = ({
  title = null,
  subtitle = null,
  instructions,
  features = [],
  className,
  ...other
}) => {
  // Populate header, other metadata from gatsby-config.js
  const siteData = useContext(SiteContext);

  return (
    <div
      {...other}
      className={classNames("container-fluid", "bg-light", "p-3", className)}
    >
      <Helmet>
        <title>
          {title} | {siteData.metadata.title}
        </title>
      </Helmet>
      {/* TODO: Decide what to do here -- This component, something else, no title? */}
      {title !== null && (
        <DocsHeading
          className={classNames("px-4", "mb-2")}
          sectionTitle={subtitle}
        >
          {title}
        </DocsHeading>
      )}
      <div className={classNames("row", "gx-0")}>
        <ViewInstructions className={classNames("col", "mx-3", "my-3", "px-1")}>
          {instructions}
        </ViewInstructions>
      </div>
      <div className={classNames("row", "gx-0", "d-flex", "flex-fill")}>
        <div
          className={classNames(
            "order-3",
            "my-2",
            "col",
            "border",
            "border-1",
            "border-light",
            "rounded-2",
            "bg-white",
            "d-flex",
            "flex-column",
            "container-fluid"
          )}
          id="content-start"
        >
          <div
            className={classNames(
              "row",
              "row-cols-1",
              // "row-cols-sm-2",
              "row-cols-md-2",
              "row-cols-lg-3",
              "row-cols-xl-4",
              "row-cols-xxl-5",
              "gx-5",
              "gy-2",
              "my-3",
              "mx-1"
            )}
          >
            {features.map((featureProps) => (
              <HeroFeatureOption key={featureProps.id} {...featureProps} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeroFeatureList };
