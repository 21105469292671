import React from "react";
import classNames from "classnames";

import { Icon } from "components/Icon";

const ViewInstructions = ({
  iconName = "informationOutline",
  className,
  children,
  ...other
}) => {
  return children ? (
    <div
      {...other}
      className={classNames(
        "instructions",
        "d-flex",
        "flex-row",
        "align-items-middle",
        "text-dark",
        "my-1",
        "py-1",
        className
      )}
    >
      <Icon
        title={"Instructions"}
        name={iconName}
        size={1.2}
        alignText={true}
        className={classNames("me-2", "flex-shrink-0", "opacity-75")}
      />
      <p className={classNames("py-0 my-0 fw-bold")}>{children}</p>
    </div>
  ) : null;
};

export { ViewInstructions };
